<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('PAGE_TITLE:Admin') }}
    </PageTitle>
    <Loader v-if="adminDashboardDataLoading" class="h-100"/>
    <t-card v-else>
      <div v-if="adminDashboardData.picturesCount > 0 || adminDashboardData.userDescriptionsCount"
        class="w-full flex flex-col gap-3"
      >
        <Button
          v-if="adminDashboardData.picturesCount > 0"
          :to="{name: 'admin-pictures'}"
          variant="link"
        >
            {{ trans('LABEL:AwaitingApprovalPictures') }}: {{ adminDashboardData.picturesCount }}
        </Button>
        <Button
          v-if="adminDashboardData.userDescriptionsCount"
          :to="{name: 'admin-descriptions'}"
          variant="link"
        >
          {{ trans('LABEL:AwaitingApprovalDescriptions') }}: {{ adminDashboardData.userDescriptionsCount }}
        </Button>
      </div>
      <div v-else>
        {{ trans('LABEL:AdminNoAction') }}
      </div>
    </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import Loader from "@/views/Loader";
import PageTitle from "@/layouts/components/pages/PageTitle";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AdminDashboard",
  components: {
    AdminPageFrame,
    Button,
    Loader,
    PageTitle,
  },
  computed: {
    ...mapGetters('admin', ['adminDashboardData', 'adminDashboardDataLoading'])
  },
  methods: {
    ...mapActions('admin', ['fetchAdminDashboardData'])
  },
  mounted() {
    this.fetchAdminDashboardData();
  }
}
</script>
